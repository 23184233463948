<template>
  <div id="admin-real-estate-development-create" class="admin-view">
    <v-row no-gutters>
      <v-col md="12">
        <external-broker-home></external-broker-home>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    ExternalBrokerHome: () =>
      import("@/components/external-broker/ExternalBrokerHome.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
